/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import {
  AutoComplete,
  Input,
  Button,
  Colors,
  Title,
  Body,
} from '@cognite/cogs.js';
import {
  CogniteAnnotation,
  CogniteAnnotationSpec,
  ItemType,
  WorkStep,
} from 'types';
import { fetchAsset, fetchItemTypes, searchAsset } from 'services/services';
import { Asset } from '@cognite/sdk';
import { useAnnotations } from 'redux/reducers/annotations';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { WarningIcon } from 'shared/Icons/Warning';
import { Line } from 'utils/test/actions';
import isNumber from 'lodash/isNumber';

export type Props = {
  annotation?: CogniteAnnotationSpec;
  oldAnnotation?: CogniteAnnotation;
  procedureNeedsMigration?: boolean;
  onFocus?: (event: React.FocusEvent) => void;
};

export const AnnotationForm = ({
  annotation,
  oldAnnotation,
  procedureNeedsMigration,
  onFocus,
}: Props) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [itemTypes, setItemTypes] = useState<ItemType[]>([]);
  const [affectedWorkSteps, setAffectedWorkSteps] = useState<WorkStep[]>([]);
  const [itemType, setItemType] = useState<ItemType>();

  let initialDetail;
  let initialAsset;

  if (annotation && 'detail' in annotation.data) {
    initialDetail = annotation.data.detail;
  }
  if (annotation && 'linkedResourceExternalId' in annotation.data) {
    initialAsset = annotation.data.linkedResourceExternalId;
  }

  const [detail, setDetail] = useState<string>(initialDetail || '');
  const [asset, setAsset] = useState<Asset>();

  let initialRelativePosition;
  if (annotation && 'relativePosition' in annotation.data) {
    initialRelativePosition = annotation.data.relativePosition;
  }
  const [relativePosition, setRelativePosition] = useState<string>(
    initialRelativePosition || '1st'
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [annotationToBeMigrated, setAnnotationToBeMigrated] = useState<
    CogniteAnnotation | undefined
  >(undefined);

  const {
    setNewAnnotationNeedRelativeRef,
    selectRelativeRef,
    selectLine,
    cancelNewAnnotation,
    saveAnnotation,
    findClosestAnnotationForVerification,
  } = useAnnotations();

  const { updateAnnotationOfWorkStepsInvalid, findWorkStepsWithAnnotation } =
    useWorkSteps();

  useEffect(() => {
    async function fetching() {
      const response = await fetchItemTypes();
      const annotationsItemTypes = [...response, Line];
      setItemTypes(annotationsItemTypes);
      if (
        annotation &&
        annotation.data &&
        'type' in annotation?.data &&
        annotation?.data?.type
      ) {
        const type = annotation?.data?.type;
        setItemType(annotationsItemTypes.find((it) => it.type === type));
      }
    }
    if (!itemTypes.length) {
      fetching();
    }
  }, [annotation, itemTypes.length]);

  useEffect(() => {
    async function fetching(assetId: number) {
      const [annotationAsset] = await fetchAsset({ id: assetId });
      setAsset(annotationAsset);
    }
    if (
      annotation &&
      annotation?.data &&
      'assetRef' in annotation?.data &&
      annotation?.data?.assetRef &&
      'Id' in annotation?.data?.assetRef &&
      annotation?.data?.assetRef?.Id
    ) {
      const id = annotation.data.assetRef.Id;
      if (isNumber(id)) {
        fetching(id);
      }
    }
  }, [annotation]);

  useEffect(() => {
    if (oldAnnotation) {
      setAffectedWorkSteps(findWorkStepsWithAnnotation(oldAnnotation));

      if (procedureNeedsMigration) {
        const prevAnnotationToBeMigrated = findClosestAnnotationForVerification(
          oldAnnotation,
          oldAnnotation.annotatedResourceId
        );
        setAnnotationToBeMigrated(prevAnnotationToBeMigrated);
      }
    }
  }, [
    oldAnnotation,
    procedureNeedsMigration,
    findWorkStepsWithAnnotation,
    findClosestAnnotationForVerification,
  ]);

  const itemHas = (value: string): boolean => {
    return !!itemType && itemType.template.includes(value);
  };

  const referenceValue = () => {
    const stringBuilder = [];
    if (
      annotation?.data &&
      'indirectRelation' in annotation.data &&
      'indirectExternalId' in annotation.data &&
      annotation.data.indirectExternalId &&
      'externalId' in annotation.data.indirectExternalId
    ) {
      stringBuilder.push(annotation.data?.indirectRelation);
      stringBuilder.push(annotation.data?.indirectExternalId?.externalId);
      if (annotation.data?.lineExternalId) {
        stringBuilder.push('on');
        stringBuilder.push(annotation.data?.lineExternalId.externalId);
      }
    }
    return stringBuilder.join(' ').trim();
  };

  const validate = () => {
    let validation = true;
    if (!itemType) {
      setErrors({ ...errors, type: 'Missing type' });
      validation = false;
    }
    return validation;
  };

  return (
    <Draggable handle=".handle">
      <div
        data-testid="annotation-form"
        style={{
          backgroundColor: 'white',
          width: '600px',
          height: 'auto',
          boxShadow: 'var(--cogs-z-12)',
        }}
        data-annotationtobemigratedid={
          annotationToBeMigrated ? annotationToBeMigrated.id : undefined
        }
        data-annotationtobemigratedrev={
          annotationToBeMigrated && annotationToBeMigrated.data
        }
      >
        <div
          className="handle"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            icon="Close"
            type="ghost"
            aria-label="Close"
            style={{ color: Colors['decorative--grayscale--black'] }}
            onClick={() => {
              cancelNewAnnotation();
            }}
          />
        </div>
        <div style={{ padding: '0 20px 20px' }}>
          <Title
            className="handle"
            level="5"
            style={{ textAlign: 'initial', paddingBottom: '3px' }}
          >
            {oldAnnotation ? 'Edit hotspot' : 'Add hotspot'}
          </Title>
          {!!affectedWorkSteps.length && (
            <div
              style={{
                padding: '16px',
                marginBottom: '10px',
                backgroundColor: '#FFF1CC',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'start',
              }}
            >
              <div style={{ display: 'flex' }}>
                <WarningIcon />
                <Title level={5} style={{ marginLeft: '5px' }}>
                  Warning
                </Title>
              </div>
              <Body level={2}>
                Editing this hotspot will require the following worksteps to be
                revised:{' '}
                {affectedWorkSteps
                  .map(
                    (affectedWorkStep: WorkStep) => affectedWorkStep.position
                  )
                  .join(', ')}
              </Body>
            </div>
          )}
          <>
            <div className="cogs-input-container">
              <label className="title">Item type</label>
              <select
                key="annotation-form-type"
                style={{
                  width: '100%',
                  height: '36px',
                  paddingLeft: '11px',
                  fontSize: '14px',
                }}
                data-testid="new-annotation-type"
                name="type"
                className={`cogs-input cogs-input-default ${
                  errors?.type ? 'has-error' : ''
                }`}
                title="Add hotspot"
                value={itemType?.type}
                onChange={({ target }: any) => {
                  const type = itemTypes.find((it) => it.type === target.value);
                  setItemType(type);
                  if (!type?.template.includes('[floc]')) {
                    setNewAnnotationNeedRelativeRef(true);
                    setAsset(undefined);
                  } else {
                    setNewAnnotationNeedRelativeRef(false);
                    setRelativePosition('');
                    selectRelativeRef(undefined);
                    selectLine(undefined);
                  }
                  if (!type?.template.includes('[detail]')) {
                    setDetail('');
                  }
                }}
              >
                <option key="option-default" value="">
                  Select type
                </option>
                {itemTypes
                  .map((it) => {
                    return { value: it.type, label: it.description };
                  })
                  .map((item) => {
                    return (
                      <option
                        key={item.value}
                        data-testid={`type-option-${item.value}`}
                        value={item.value}
                      >
                        {item.label}
                      </option>
                    );
                  })}
              </select>
              <div className="error-space" data-testid="type-error">
                {errors?.type}
              </div>
            </div>
            <div
              className="cogs-input-container cogs-body-2"
              style={{
                opacity:
                  itemHas('[detail]') || (itemType && itemType.type === 'line')
                    ? '1'
                    : '0.5',
                fontSize: '14px',
              }}
            >
              <Input
                title="Detail specification"
                placeholder="Enter detail"
                name="detail"
                autoComplete="off"
                data-testid="new-annotation-detail"
                fullWidth
                disabled={
                  !itemHas('[detail]') && itemType && itemType.type !== 'line'
                }
                onChange={({ target }) => {
                  setDetail(target.value);
                }}
                value={detail}
              />
            </div>
            <div
              className="cogs-input-container "
              style={{
                opacity: itemHas('[floc]') ? '1' : '0.5',
              }}
            >
              <label
                className="title"
                style={{
                  cursor: !itemHas('[floc]') ? 'not-allowed' : 'default',
                }}
              >
                FLOC
              </label>
              <AutoComplete
                data-testid="autocomplete"
                styles={{
                  control: (styles: any, { isDisabled }: any) => ({
                    padding: '0 7px',
                    border: '1px solid',
                    borderColor: isDisabled
                      ? Colors['decorative--grayscale--500']
                      : '#94949f',
                    backgroundColor: isDisabled
                      ? Colors['decorative--grayscale--300']
                      : Colors['surface--muted'],
                    textTransform: 'none',
                  }),
                }}
                isDisabled={!itemHas('[floc]')}
                placeholder="Start typing for FLOC"
                value={
                  asset
                    ? { value: asset, label: asset?.externalId }
                    : { value: initialAsset, label: initialAsset }
                }
                options={assets.map((it: Asset) => {
                  return {
                    value: it,
                    label: it.externalId,
                  };
                })}
                handleInputChange={(newValue: string) => {
                  if (newValue) {
                    searchAsset({ name: newValue }).then((response) => {
                      setAssets(response);
                    });
                  }
                }}
                onChange={(result: any) => {
                  setAsset(result.value);
                }}
              />
            </div>
            <div
              className="cogs-input-container"
              style={{
                opacity: !itemType || itemHas('[floc]') ? '0.5' : '1',
                fontSize: '14px',
              }}
            >
              <label
                className="title"
                style={{
                  cursor: !itemHas('[floc]') ? 'not-allowed' : 'default',
                }}
              >
                Relative position
              </label>
              <select
                disabled={!itemType || itemHas('[floc]')}
                style={{
                  width: '100%',
                  height: '36px',
                  paddingLeft: '11px',
                  fontSize: '14px',
                }}
                className={`cogs-input cogs-input-default ${
                  errors?.type ? 'has-error' : ''
                }`}
                key="new-annotation-position-options"
                data-testid="new-annotation-position-options"
                value={relativePosition}
                onChange={({ target }: any) => {
                  setRelativePosition(target.value);
                }}
              >
                {['1st', '2nd', '3rd'].map((option) => {
                  return (
                    <option
                      data-testid={`position-option-${option}`}
                      key={`position-option-${option}`}
                      value={option}
                    >
                      {option}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              className="cogs-input-container"
              style={{
                opacity: !itemType || itemHas('[floc]') ? '0.5' : '1',
                fontSize: '14px',
              }}
            >
              <Input
                title="Location"
                placeholder="Select relative reference and line no."
                name="detail"
                disabled={!itemType || itemHas('[floc]')}
                autoComplete="off"
                data-testid="new-annotation-location"
                readOnly
                fullWidth
                value={referenceValue()}
                postfix={
                  <Button
                    disabled={!itemType || itemHas('[floc]')}
                    type="secondary"
                    onClick={() => {
                      selectRelativeRef(undefined);
                      selectLine(undefined);
                      setNewAnnotationNeedRelativeRef(true);
                    }}
                  >
                    Clear
                  </Button>
                }
                onFocus={onFocus}
              />
            </div>
            <div style={{ textAlign: 'end' }}>
              <Button
                type="ghost"
                data-testid="cancel-new-annotation"
                onClick={() => {
                  cancelNewAnnotation();
                }}
                style={{ marginTop: 8 }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                data-testid="create-new-annotation"
                onClick={() => {
                  const result = validate();
                  if (result) {
                    const userAnnotation = {
                      ...annotation,
                      data: {
                        ...annotation?.data,
                        ...(detail && {
                          detail,
                        }),
                        type: itemType?.type,
                        linkedResourceType: 'asset',
                        linkedResourceInternalId: asset?.id,
                        linkedResourceExternalId: asset?.externalId,
                        sourceType: 'user',
                        ...(relativePosition && { relativePosition }),
                      },
                    } as CogniteAnnotation;

                    if ('color' in userAnnotation) {
                      delete userAnnotation.color;
                    }
                    if ('annotatedResourceExternalId' in userAnnotation) {
                      delete userAnnotation.annotatedResourceExternalId;
                    }

                    saveAnnotation(
                      userAnnotation,
                      oldAnnotation,
                      updateAnnotationOfWorkStepsInvalid,
                      annotationToBeMigrated
                    );
                  }
                }}
                style={{ marginTop: 8, marginLeft: '5px' }}
              >
                {oldAnnotation ? 'Save hotspot' : 'Add hotspot'}
              </Button>
            </div>
          </>
        </div>
      </div>
    </Draggable>
  );
};
